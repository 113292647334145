<template>
  <div class="tamplate">
    <Nav></Nav>
    <article id="main-article">
      <div>
        <h1>Projeler sayfası!</h1>
      </div>
    </article>
    <Footer></Footer>
  </div>
</template>
  <script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Projects",
  components: {
    Nav: Nav,
    Footer: Footer,
  },
};
</script>
  <style scoped src='../styles/style.css'>
</style>