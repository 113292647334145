<template>
  <div class="tamplate">
    <Nav></Nav>
    <article id="main-article">
      <!-- Hi section start -->
      <div id="hi-div">
        <div id="hi-main">
          <div id="hi-main-info-div">
            <h1>Hi, I’m Bestami</h1>
             <div>
            <span id="typedElementId" ref="typedElement"></span>
            </div>
          </div>
          <article id="hi-photos-article">
            <a
              target="_blank"
              href="https://unsplash.com/photos/S2CWsE_xHAk"
              id="first-pic-div"
            >
              <img
                class="hi-pic"
                src="../images/Watches.png"
                alt="unsplash-photo"
              />
            </a>

            <a
              target="_blank"
              href="https://unsplash.com/photos/black-laptop-computer-turned-on-displaying-windows-7-kdXzw0fd0TU"
              id="second-pic-div"
            >
              <img
                class="hi-pic"
                src="../images/code.png"
                alt="unsplash-photo"
              />
            </a>
            <a
              target="_blank"
              href="https://unsplash.com/photos/fEtm1xCIyn0"
              id="third-pic-div"
            >
              <img
                class="hi-pic"
                src="../images/Porsche.png"
                alt="unsplash-photo"
              />
            </a>
          </article>
        </div>
        <section id="hi-bg"></section>
      </div>
      <!-- Hi section end -->

      <!-- ProCreate section start -->
      <div id="procreate-div">
        <div id="procreate-main">
          <div id="my-procreate-div">
            <img
              id="my-procreate-pic"
              src="../images/MyPP.png"
              alt="procreate-pic"
            />
            <div></div>
          </div>
          <article id="sample-pic">
            <h1 id="procreate-text">ProCreate 🎨</h1>
            <section id="procreate-section">
              <div class="procreate-sample-bg">
                <img
                  class="procreate-pic"
                  src="../images/DJI.png"
                  alt="procreate-pic"
                />
              </div>
              <div class="procreate-sample-bg">
                <img
                  class="procreate-pic"
                  src="../images/Beats.png"
                  alt="procreate-pic"
                />
              </div>
              <div class="procreate-sample-bg">
                <img
                  class="procreate-pic"
                  src="../images/Corolla.png"
                  alt="procreate-pic"
                />
              </div>
            </section>
            <div id="sample-pic-text">
              <p>Art without&nbsp;</p>
              <p style="color: red">limits&nbsp;</p>
              <p>.</p>
            </div>
          </article>
        </div>
        <section id="procreate-bg"></section>
      </div>

      <!-- ProCreate section end -->

      <!-- Unsplash section start -->
      <div id="unsplash-div">
        <div id="unsplash-main">
          <div id="unsplash-info">
            <p id="unsplash-text">Unsplash 📸</p>
            <a target="_blank" :href="lastPicLink" id="unsplash-last-photo">
              <img :src="lastPic" alt="unsplash-last-photo" />
            </a>
            <div id="unsplash-view-down-div">
              <div id="unsplash-views">
                <p id="stat-text">Views</p>

                <p id="stat-count">{{ unsplashViews }}</p>
              </div>
              <div id="unsplash-down">
                <p id="stat-text">Downloads</p>

                <p id="stat-count">{{ unsplashDownloads }}</p>
              </div>
            </div>
          </div>
          <div id="unsplash-bg-pic">
            <img src="../images/unsplash.png" alt="unsplash-logo" />
            <div id="unsplash-dot-bg"></div>
          </div>
        </div>
        <section id="unsplash-bg"></section>
      </div>
      <!-- Unsplash section end -->

      <!-- Webdev section start -->
      <div id="dev-div">
        <div id="dev-main">
          <div id="dev-group">
            <img id="first-dev-pic" src="../images/webpage.png" alt="webpage" />
            <div>
              <p>Dev</p>
              <p>🛠</p>
            </div>
            <img
              id="thirth-dev-pic"
              src="../images/yakitim.png"
              alt="yakıtım"
            />
          </div>
          <div id="dev-pic-group">
            <img
              id="second-dev-pic"
              src="../images/desktop.png"
              alt="sosyofi"
            />
            <img
              id="fourth-dev-pic"
              src="../images/entrance.png"
              alt="entrance"
            />
          </div>
        </div>
        <section id="dev-bg">
          <div></div>
        </section>
      </div>
      <!-- Webdev section end -->

      <div id="bottom-div"></div>
    </article>

    <Footer></Footer>
  </div>
</template>
  <script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
import Typed from 'typed.js';
export default {
  name: "Home",
  components: {
    Nav: Nav,
    Footer: Footer,
  },
  mounted() {
     const options = {
      strings: ['Full-Stack Developer', 'DevOps', 'System Administrator'], // Yazılacak metinler
      typeSpeed: 50, // Yazma hızı
      backSpeed: 25, // Silme hızı
      loop: true // Döngü
    };

    // Typed.js'i başlat
    this.typed = new Typed(this.$refs.typedElement, options);
  },
   beforeDestroy() {
    // Bileşen yok edildiğinde Typed.js'i durdur
    if (this.typed) {
      this.typed.destroy();
    }
  },
  methods: {
    splitNum(num) {
      if (parseInt(num / 100) > 0) {
        return num;
      } else if (parseInt(num / 10) > 0) {
        return "0" + num;
      } else if (parseInt(num / 1) > 0) {
        return "00" + num;
      } else {
        return "000";
      }
    },
    fetchStat() {
      fetch(
        "https://api.unsplash.com/users/bbestamis/statistics/?client_id=" +
          this.accesKey
      )
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          let downloads = json.downloads.total;
          if (parseInt(downloads / 1000) == 0) {
            this.unsplashDownloads = this.splitNum(downloads);
          } else {
            if (parseInt(downloads / 1000000) == 0) {
              this.unsplashDownloads =
                parseInt(downloads / 1000) +
                "." +
                this.splitNum(downloads % 1000);
            } else {
              this.unsplashDownloads =
                parseInt(downloads / 1000000) +
                "." +
                this.splitNum(parseInt((downloads % 1000000) / 1000)) +
                "." +
                this.splitNum(downloads % 1000);
            }
          }

          let views = json.views.total;
          if (parseInt(views / 1000) == 0) {
            this.unsplashViews = this.splitNum(views);
          } else {
            if (parseInt(views / 1000000) == 0) {
              this.unsplashViews =
                parseInt(views / 1000) + "." + this.splitNum(views % 1000);
            } else {
              this.unsplashViews =
                parseInt(views / 1000000) +
                "." +
                this.splitNum(parseInt((views % 1000000) / 1000)) +
                "." +
                this.splitNum(views % 1000);
            }
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    fetchPhoto() {
      fetch(this.url + this.accesKey)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.lastPic = json[0].urls.regular;
          this.lastPicLink = json[0].links.html;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
  created() {
    this.fetchStat();
    this.fetchPhoto();
  },
  data() {
    return {
      lastPicLink: "",
      unsplashDownloads: "",
      unsplashViews: "",
      lastPic: "",
      accesKey: "QOgERd_E-NY7hjaAjAzBG1dIw-OhZrTcqdm_lxE2zuk",
      url: "https://api.unsplash.com/users/bbestamis/photos/?client_id=",
    };
  },
};
</script>
  <style scoped src='../styles/style.css'>
</style>
