<template>
<div class="tamplate" >
    <Nav></Nav>
    <article id='main-article'>
     <div>
         <h1>Hakkımda kısmı!</h1>
     </div>       
    </article>
</div>
</template>
  <script>
import Nav from '../components/Nav.vue'
    export default{
      name: 'Home',
                components: {
                    'Nav': Nav,
                }
  }
  </script>
  <style scoped src='../styles/style.css'>
  </style>
