<template>
  <router-view />
  <div id="cursor">
    <div></div>
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";

export default {
  name: "App",
  data() {
    return {
      cursorElement: document.getElementById("cursor"),
    };
  },
  mounted() {
    this.js();
  },
  methods: {
    js() {
      document.addEventListener("mousemove", (e) => {
        cursor.setAttribute(
          "style",
          "top: " + (e.pageY - 10) + "px; left: " + (e.pageX - 10) + "px;"
        );
      });
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700;800&display=swap");
* {
  margin: 0px;
  padding: 0px;
  cursor: none;
  border-box: box-sizing;
}

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#cursor {
  z-index: 999999;
  width: 20px;
  height: 20px;

  position: absolute;
  pointer-events: none;
  transition: 0.05s;
  transition-timing-function: ease-out;
}
#cursor div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #27f579;
}
</style>
