<template>
  <article id="nav-article">
    <div id="navbar">
      <div class="nav-items">
        <div class="logo"></div>
        <div id="nav-link-div">
         <router-link @click.prevent="downloadPDF" to="#">
          Resume
        </router-link>
        <a href="https://github.com/BBestamiS?tab=repositories" target="_blank" rel="noopener noreferrer">
          Projects
        </a>

    
        </div>
      </div>
      <div class="nav-bg"></div>
    </div>
  </article>
</template>
<script>
import App from "../App.vue";
export default {
  name: "Nav",
  data() {
    return {
      data: this.$route.fullPath,
      projects: "Projects",
      projectspath: "/projects",
      resume: "Resume",
      resumepath: "/resume",
      contact: "Contact",
      contactpath: "/contact",
      about: "About",
      aboutpath: "/about",
      navlinkdiv: "",
    };
  },
  mounted() {
    this.bind();
    this.asd();
  },
  methods: {
    downloadPDF() {
      const link = document.createElement('a');
      link.href = '/cv.pdf'; // PDF dosyanızın yolu
      link.download = 'Beyazit-Bestami-SARIKAYA-Resume.pdf'; // İndirme sırasında dosyanın adı
      link.click();
    },
    asd() {
      for (let index = 0; index < this.navlinkdiv.children.length; index++) {
        this.cursorOpacity(this.navlinkdiv.children[index]);
      }
    },
    bind() {
      this.navlinkdiv = document.getElementById("nav-link-div");
    },
    cursorOpacity(element) {
      element.addEventListener("mouseover", (e) => {
        App.data.call().cursorElement.children[0].style.opacity = "0%";
        element.setAttribute("style", "background-color: #27f579;");
      });
      element.addEventListener("mouseout", (e) => {
        App.data.call().cursorElement.children[0].style.opacity = "100%";
        element.setAttribute("style", "background-color: transparent;");
      });
    },

    isthispage: function (page) {
      if (this.data === "/") {
        return true;
      } else if (this.data === "/projects") {
        if (page === "projects") {
          this.projects = "Home";
          this.projectspath = "/";
          return true;
        }
      } else if (this.data === "/contact") {
        this.contact = "Home";
        this.contactpath = "/";
        return true;
      }
    },
  },
};
</script>
<style scoped src='../styles/nav-style.css'>
</style>
